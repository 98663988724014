<template>
  <div>
    <listing-head class="d-none d-md-block" />
    <template v-if="!getIsLoadingOrders">
      <template v-if="hasOrders">
        <listing-row
          v-for="(order, index) in getOrders"
          :key="index"
          :order="order"
          @openModal="openModal(order)"
        />
        <default-pagination
          v-model="currentPage"
          :length="getPagination.lastPage"
          @input="changePage"
        />
      </template>
    </template>
    <default-spinner v-else />
    <order-details
      v-model="isModalOpen"
      :order-details="getOrderDetails"
      :bloqueioId="bloqueioId"
      :key="'reRender' + reRender"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import DefaultPagination from "@/shared/components/vuetify/DefaultPagination.vue";
import DefaultSpinner from "@/shared/components/vuetify/DefaultSpinner.vue";
import ListingHead from "./ListingHead.vue";
import ListingRow from "./ListingRow.vue";
import OrderDetails from "./OrderDetails.vue";

export default {
  name: "TheListing",
  components: {
    DefaultPagination,
    DefaultSpinner,
    ListingHead,
    ListingRow,
    OrderDetails,
  },
  data() {
    return {
      isModalOpen: false,
      reRender: 0,
      bloqueioId: "",
    };
  },
  created() {
    this.orders();
  },
  methods: {
    ...mapActions("orderSupervision", ["orders", "setUrlParams"]),
    changePage() {
      this.orders();
    },
    openModal(order) {
      this.bloqueioId = order.bloqueioPedidoId ?? "0";
      this.isModalOpen = true;
      this.reRender++;
    },
  },
  computed: {
    ...mapGetters("orderSupervision", [
      "getOrders",
      "getOrderDetails",
      "getIsLoadingOrders",
      "getPagination",
      "getUrlParams",
    ]),
    hasOrders() {
      return this.getOrders.length > 0;
    },
    currentPage: {
      get() {
        return this.getUrlParams.page;
      },
      set(newValue) {
        this.setUrlParams({ ...this.getUrlParams, page: newValue });
      },
    },
  },
};
</script>

<style scoped></style>
