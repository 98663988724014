<template>
  <default-modal v-model="dialogModel" width="700" v-if="value">
    <template v-if="!getIsLoadingOrderDetails">
      <div v-if="isLoading">
        <v-card-title class="justify-center">
          <span class="text-h6 font-weight-bold navy--text"
            >Erro ao Carregar Dados</span
          >
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12" class="text-center">
              <v-icon color="red">mdi-alert-circle</v-icon>
              <span class="mx-2">Erro ao carregar os detalhes do pedido.</span>
            </v-col>
          </v-row>
        </v-card-text>
      </div>
      <div class="py-0" v-else>
        <v-card-title class="font-weight-bold pt-0"
          >Detalhes do Pedido {{ orderDetails.numeroPedido }}
          <v-col cols="4" sm="3">
            <v-chip
              class="d-block font-weight-bold text-center"
              :color="colorChip(orderDetails.bloqueioStatus)"
              text-color="white"
            >
              {{ orderDetails.bloqueioStatus | filterStatus }}
            </v-chip>
          </v-col>
        </v-card-title>
        <v-card-text>
          <v-row dense>
            <v-col cols="6">
              <span class="d-block mb-n1 font-weight-bold">Modelo</span>
              <span v-if="orderDetails.modeloPainel.length === 0"
                >Sem Modelo para este Pedido</span
              >
              <span
                v-for="(panelModel, index) in orderDetails.modeloPainel"
                :key="index"
                class="d-block h6 text-uppercase"
                >- {{ panelModel.tipoInsumoDescricao }}</span
              >
            </v-col>
            <v-col cols="6">
              <span class="d-block mb-n1 font-weight-bold">Separadores</span>
              <div v-if="orderDetails.duplaDeSeparadores.length">
                <span class="d-block text-truncate"
                  >{{
                    orderDetails.duplaDeSeparadores[0].separador1 == ""
                      ? "Sem Separadores para este Pedido"
                      : orderDetails.duplaDeSeparadores[0].separador1
                  }}
                </span>
                <span class="h6 text-uppercase d-block text-truncate">{{
                  orderDetails.duplaDeSeparadores[0].separador2
                }}</span>
              </div>
              <div v-else>
                <span class="d-block text-truncate"
                  >Sem Separadores para este Pedido</span
                >
              </div>
            </v-col>
            <v-col cols="6">
              <span class="d-block mb-n1 font-weight-bold"
                >Desbloqueado por</span
              >
              <span
                class="h6 text-uppercase"
                v-if="orderDetails.desbloqueadoPor"
                >{{ orderDetails.desbloqueadoPor }}</span
              >
              <span v-else> --- </span>
            </v-col>
            <v-col cols="6">
              <span class="d-block mb-n1 font-weight-bold"
                >Desbloqueado em</span
              >
              <span
                class="h6 text-uppercase"
                v-if="orderDetails.desbloqueadoEm"
                >{{ orderDetails.desbloqueadoEm | dateDMY }}</span
              >
              <span v-else> --- </span>
            </v-col>
            <v-col cols="6">
              <span class="d-block mb-n1 font-weight-bold">Bloqueado por</span>
              <span
                class="h6 text-uppercase"
                v-if="orderDetails.bloqueadoPor"
                >{{ orderDetails.bloqueadoPor }}</span
              >
              <span v-else class="h6"> --- </span>
            </v-col>
            <v-col cols="6">
              <span class="d-block mb-n1 font-weight-bold">Bloqueado em</span>
              <span class="h6 text-uppercase" v-if="orderDetails.bloqueadoEm">{{
                orderDetails.bloqueadoEm | dateDMY
              }}</span>
              <span v-else class="h6">--- </span>
            </v-col>
            <v-col cols="6">
              <span class="d-block mb-n1 font-weight-bold"
                >Motivo do Bloqueio</span
              >
              <span
                class="d-block h6 text-uppercase"
                v-if="orderDetails.tipoErro"
                >{{ orderDetails.tipoErro }}</span
              >
              <span v-else class="h6"> --- </span>

              <div v-show="isDiscrepantPanelModelShown">
                <span
                  style="font-size: 13px"
                  class="d-block"
                  v-for="(
                    discrepantPanelModel, index
                  ) in orderDetails.modeloPainelDivergente"
                  :key="index"
                  ><v-icon color="red" small>mdi-close</v-icon>
                  {{ discrepantPanelModel.tipoInsumoDescricao }}</span
                >
              </div>
              <span
                v-if="orderDetails.modeloPainelDivergente.length > 0"
                @click="
                  isDiscrepantPanelModelShown = !isDiscrepantPanelModelShown
                "
                style="cursor: pointer"
                class="d-block primary--text font-weight-bold text-decoration-underline"
                >{{
                  isDiscrepantPanelModelShown
                    ? "Ocultar Modelos Divergentes"
                    : "Ver Modelos Divergentes"
                }}</span
              >
            </v-col>
            <v-col cols="6" v-if="orderDetails.volumes">
              <span
                @click="toggleInspectionDetails"
                style="cursor: pointer"
                class="primary--text font-weight-bold text-decoration-underline"
                >{{
                  isInspectionDetailsShown
                    ? "Ocultar Conferência do Pedido"
                    : "Ver Conferência do Pedido"
                }}</span
              >
            </v-col>
          </v-row>
        </v-card-text>
      </div>
      <div v-show="isInspectionDetailsShown">
        <v-divider></v-divider>
        <v-card-title class="font-weight-bold"
          >Conferência do Pedido</v-card-title
        >
        <v-card-text>
          <v-row dense>
            <v-col cols="6">
              <span class="d-block mb-n1 font-weight-bold">Pedido</span>
              <span class="h6 text-uppercase">{{
                orderDetails.numeroPedido
              }}</span>
            </v-col>
            <v-col>
              <span class="d-block mb-n1 font-weight-bold"
                >Descrição do Kit</span
              >
              <span class="h6 text-uppercase"
                >{{ orderDetails.kitDescricao }}
              </span>
            </v-col>
            <v-col cols="6">
              <span class="d-block mb-n1 font-weight-bold">Cliente</span>
              <span class="h6 text-uppercase">{{
                orderDetails.clienteNome
              }}</span>
            </v-col>
            <v-col cols="6">
              <span class="d-block mb-n1 font-weight-bold"
                >Total de Painéis</span
              >
              <span class="h6 text-uppercase">{{
                orderDetails.quantidadePaineis
              }}</span>
            </v-col>
            <v-col cols="6">
              <span class="d-block mb-n1 font-weight-bold">Volumes</span>
              <span
                class="h6 text-uppercase d-block"
                v-for="(volume, index) in orderDetails.volumes"
                :key="index"
                :inner-html.prop="volume.codigoVolume | formatVolume"
              ></span>
            </v-col>
            <v-col cols="3">
              <span class="d-block mb-n1 font-weight-bold"
                >Painéis Bipados</span
              >
              <span
                class="h6 text-uppercase d-block"
                v-for="(volume, index) in orderDetails.volumes"
                :key="index"
                >{{ volume.conferencia }}</span
              >
            </v-col>
            <v-col cols="3">
              <span class="d-block mb-n1 font-weight-bold"
                >Painéis Conferidos</span
              >
              <span
                class="h6 text-uppercase d-block"
                v-for="(volume, index) in orderDetails.volumes"
                :key="index"
                >{{ volume.paineisBipados | formatInspectedPanels }}
                <v-icon
                  v-if="volume.paineisBipados > 0"
                  :color="
                    allPanelsBeeped(volume.paineisBipados, volume.conferencia)
                      ? 'green'
                      : 'red'
                  "
                  small
                  >{{
                    allPanelsBeeped(volume.paineisBipados, volume.conferencia)
                      ? "mdi-check"
                      : "mdi-close"
                  }}</v-icon
                ></span
              >
            </v-col>
          </v-row>
        </v-card-text>
      </div>
      <div
        style="position: sticky; bottom: 0"
        class="base_dark"
        v-if="!isLoading"
      >
        <v-divider />
        <v-col cols="6" offset="3">
          <default-button
            @click="
              unblockOrder(orderDetails.pedidoId, orderDetails.numeroPedido)
            "
            :disabled="
              orderDetails.bloqueioStatus !== 'BLOQUEADO' ||
              orderDetails.sistemaBloqueio === 'SO'
            "
            >Desbloquear</default-button
          >
        </v-col>
      </div>
      <div
        style="position: sticky; bottom: 0"
        class="base_dark"
        v-if="!isLoading"
        v-show="
          orderDetails.bloqueioStatus === 'BLOQUEADO' &&
          orderDetails.sistemaBloqueio === 'SO'
        "
      >
        <v-col cols="12" style="text-align: center">
          <span> Você não tem permissão para desbloquar este pedido. </span>
        </v-col>
      </div>
    </template>

    <default-spinner
      v-if="getIsLoadingOrderDetails"
      class="text-center py-15"
    />
  </default-modal>
</template>

<script>
import { mapGetters } from "vuex";
import DefaultButton from "@/shared/components/vuetify/DefaultButton.vue";
import DefaultModal from "@/shared/components/vuetify/DefaultModal.vue";
import DefaultSpinner from "@/shared/components/vuetify/DefaultSpinner.vue";

export default {
  name: "OrderDetails",
  data() {
    return {
      isInspectionDetailsShown: false,
      isDiscrepantPanelModelShown: false,
    };
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    orderDetails: {
      type: Object,
      required: true,
      default: () => {},
    },
    bloqueioId: {
      type: Number,
      required: true,
      default: 0,
    },
  },
  components: {
    DefaultButton,
    DefaultModal,
    DefaultSpinner,
  },
  methods: {
    toggleInspectionDetails() {
      this.isInspectionDetailsShown = !this.isInspectionDetailsShown;
    },
    allPanelsBeeped(beepedPanels, totalPanels) {
      return beepedPanels === totalPanels && totalPanels > 0;
    },
    unblockOrder(orderId, orderNumber) {
      const bloqueioId = this.bloqueioId || 0;
      const body = {
        orderId,
        orderNumber,
        bloqueioId,
      };
      this.$store.dispatch("orderSupervision/unblockOrder", body);
    },
    colorChip(value) {
      switch (value) {
        case "DESBLOQUEADO":
          return "green";
        case "BLOQUEADO":
          return "pink";
        default:
          return "primary";
      }
    },
  },
  computed: {
    ...mapGetters("orderSupervision", ["getIsLoadingOrderDetails"]),
    isLoading: {
      get() {
        return (
          this.orderDetails === undefined ||
          this.orderDetails === null ||
          Object.keys(this.orderDetails).length === 0
        );
      },
    },
    dialogModel: {
      get() {
        return this.value;
      },
      set(newValue) {
        this.$emit("input", newValue);
      },
    },
  },
  filters: {
    formatInspectedPanels(inspectedPanels) {
      return (
        {
          0: "A CONFERIR",
        }[inspectedPanels] ?? inspectedPanels
      );
    },
    filterStatus(value) {
      switch (value) {
        case "DESBLOQUEADO":
          return "Desbloqueado";
        case "BLOQUEADO":
          return "Bloqueado";
        default:
          return "Liberado";
      }
    },
  },
};
</script>

<style scoped></style>
