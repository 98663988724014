<template>
  <default-table-body-row>
    <v-col cols="12" sm="6" md="2">
      <span class="d-block d-md-none font-weight-bold">Pedido</span>
      <span
        class="d-block text-wrap font-weight-bold secondary--text mb-n2"
        :title="order.numeroPedido"
      >
        {{ order.numeroPedido }}
      </span>
      <span
        class="text-overline font-weight-light text--text"
        :title="order.dataProducao | dateDMY"
      >
        {{ order.dataProducao | dateDMY }}
      </span>
    </v-col>
    <v-col cols="12" sm="6" md="4">
      <span class="d-block d-md-none font-weight-bold">Cliente</span>
      <span class="d-block text-wrap mb-n1" :title="order.clienteNome">
        {{ order.clienteNome }}
      </span>
      <span
        class="text-wrap font-weight-light secondary--text"
        :title="order.kitDescricao"
      >
        {{ order.kitDescricao }}
      </span>
    </v-col>
    <v-col cols="12" sm="6" md="3">
      <span class="d-block d-md-none font-weight-bold">Transportadora</span>
      <span :title="order.transportadoraNome">{{
        order.transportadoraNome
      }}</span>
    </v-col>
    <v-col cols="12" sm="6" md="2" align-self="center">
      <v-chip
        class="d-block font-weight-bold"
        :color="colorChip(order.bloqueioStatus)"
        text-color="white"
      >
        {{ order.bloqueioStatus | filterStatus }}
      </v-chip>
    </v-col>
    <v-col cols="12" md="1">
      <default-fab
        tooltip-text="Ver Detalhes"
        @click="openModal(order.numeroPedido, order.bloqueioPedidoId)"
      >
        <v-icon>mdi-text-box-search</v-icon>
      </default-fab>
    </v-col>
  </default-table-body-row>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import DefaultFab from "@/shared/components/vuetify/DefaultFab.vue";
import DefaultTableBodyRow from "@/shared/components/vuetify/DefaultTableBodyRow.vue";

export default {
  name: "ListingRow",
  components: {
    DefaultFab,
    DefaultTableBodyRow,
  },
  props: {
    order: {
      type: Object,
      required: true,
    },
  },
  methods: {
    ...mapActions("orderSupervision", ["getOrderDetails"]),
    openModal(orderNumber, bloqueioId) {
      this.$emit("openModal");
      const bloqueio = bloqueioId ?? "0";
      const body = {
        bloqueioId: bloqueio,
        orderNumber: orderNumber,
      };
      this.getOrderDetails(body);
    },
    colorChip(value) {
      switch (value) {
        case "DESBLOQUEADO":
          return "green";
        case "BLOQUEADO":
          return "pink";
        default:
          return "primary";
      }
    },
  },
  computed: {
    ...mapGetters("orderSupervision", ["getUrlParams"]),
  },
  filters: {
    filterStatus(value) {
      switch (value) {
        case "DESBLOQUEADO":
          return "Desbloqueado";
        case "BLOQUEADO":
          return "Bloqueado";
        default:
          return "Liberado";
      }
    },
  },
};
</script>

<style scoped></style>
